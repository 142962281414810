.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#myColor:disabled{
  opacity: 0.5;
  cursor: initial;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

canvas {
  border: 1px solid black;
  margin-top: 2px;
  margin: 2px;
}
body {
  background-color: rgb(220, 220, 220);
}


body {
  position: fixed;
  width: 100%;
  overflow: hidden;
}
body {
  -webkit-user-select: none;  /* Chrome, Safari and Opera */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* IE/Edge */
  user-select: none;          /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.app-footer{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
}
.app-footer *{
  margin-left: 20px;
}

/*
@media only screen and (max-width: 700px) {
  .controls-container {
    position: absolute;
    top: 0;
    background-color: wheat;
  }
  video{
    height: 100px;
    width: 200px;
    background-color: rgb(0, 0, 0);
    margin-top: 25px;
  }
  .view-controls-button{
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
  */


.hidden {
  display: none;
}

.play-button{
  width: 100px;
}
.frame-controls{
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid rgb(73, 73, 73);
  border-radius: 3px;
}
.frame-controls:hover{
  cursor: pointer;
  background-color: rgb(238, 238, 238);
}
.frame-controls:active{
  background-color: rgb(201, 201, 201);
}
.frame-controls:disabled{
  opacity: 0.5;
  background-color: rgb(227, 227, 227);
}
.frame-control-container{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.button-loop-video{
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
}

.button-loop-video:hover{
  cursor: pointer;
  background-color: rgb(238, 238, 238);

}

.layer-button:hover{
  cursor: pointer;
  background-color: rgb(208, 216, 224);

}
/* */


.canvas-container{
  display: block;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-mask {
  display: block;
  height: 415px;
  width: 415px;
  overflow: hidden;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

canvas{
  position:absolute;
  pointer-events: none;
  opacity: 1;
}

canvas {   
  image-rendering: pixelated; 
}

.canvas-main{
  /*
  background-image: linear-gradient(45deg, #a1a1a1 25%, transparent 25%), linear-gradient(-45deg, #a1a1a1 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #a1a1a1 75%), linear-gradient(-45deg, transparent 75%, #a1a1a1 75%);
  */
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  image-rendering: pixelated; 
  pointer-events: auto;
  opacity: 1;
  z-index: 10;
}
.canvas-layer-2{
  /*
  background-image: linear-gradient(45deg, #a1a1a1 25%, transparent 25%), linear-gradient(-45deg, #a1a1a1 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #a1a1a1 75%), linear-gradient(-45deg, transparent 75%, #a1a1a1 75%);
  */
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  image-rendering: pixelated; 
  pointer-events: auto;
  opacity: 1;
  z-index: 9;
}

.pre-color-select{
  height: 30px;
  width: 30px;
  background-color: brown;
  border: 4px solid rgb(235, 235, 235);
  margin: 4px;
  box-shadow: rgba(60, 64, 67, 0.4) 0px 1px 2px 0px, rgba(60, 64, 67, 0.3) 0px 1px 3px 1px;
}

.pre-color-select:hover{
  cursor: pointer; 
  border: 4px solid rgb(207, 207, 207);
}

.canvas-bg{
  background-color: rgb(255, 255, 255);
}

.canvas-onion-1{
  opacity: 0.3;
  z-index: 2;
}
.canvas-onion-2{
  opacity: 0.2;
  z-index: 1;
}
.canvas-onion-3{
  opacity: 0.1;
}

.onion-skin-button{
  margin: auto;
}

.button-ui-test{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border: 1px solid black;
  width: min-content;
  border-radius: 10px;
  background: linear-gradient(to right, rgb(152, 152, 254) 50%, rgb(150, 150, 150) 50%);
}

.button-ui-test img{
  height: 25px;
  width: 25px;
  padding: 2px;
  padding-left: 5px;
}

.ui-btn-r{
  border-left: 1px solid black;
}
.ui-btn-l{
  border-right: 1px solid black;
}

.button-ui-test button{
  border: none;
  background-color: rgb(180, 180, 255);
  color: white;
  font-size: 20px;
  height: 29px;
  text-align: center;
  width: 65px;
}

.button-ui-test button:hover{
  background-color: rgb(150, 150, 255);
  cursor: pointer;
}

.container-ui-test{
  margin: 10px;
}

.ui-empty{
  height: 25px;
  width: 25px;
  padding: 2px;
}

.canvas-tools{
  height: 500px;
    overflow-y: scroll;
}


@media only screen and (max-height: 1024px) and (max-width: 770px) {
  .canvas-tools{
    height: 650px;
    overflow-y: scroll;
  }

}


@media only screen and (max-height: 950px) and (max-width: 440px) {
  .canvas-tools{
    height: 500px;
    overflow-y: scroll;
    
  }
}

/*iPhone 12 Pro*/
@media only screen and (max-height: 900px) and (max-width: 540px) {
  .canvas-tools{
    height: 400px;
    overflow-y: scroll;
  }
}
/*surface duo*/
@media only screen and (max-height: 720px) and (max-width: 540px) {
  .canvas-tools{
    height: 325px;
    overflow-y: scroll;
  }

}

@media only screen and (max-height: 750px) and (max-width: 400px) {
  .canvas-tools{
    height: 310px;
    overflow-y: scroll;
  }

}

@media only screen and (max-height: 700px) {
  .canvas-tools{
    height: 250px;
    overflow-y: scroll;
  }

}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}



.pre-color-select:disabled{
  border-color: rgb(214, 214, 214);
}

.render-progress{
  background-color: rgb(197, 150, 233);
  border-radius: 8px;
  position: absolute;
  left: 50%; /* Center horizontally */
  transform: translate(-50%, 0%); /* Adjust the position to truly center the element */
  margin: auto;
  z-index: 200;
  top: 20px;
  border: 2px solid black;
  padding: 20px;
}

.render-progress p{
  margin: 0px;
}

body {
  background-size: 20px 20px;
  background-image:
    linear-gradient(to right, rgba(128, 128, 128, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(128, 128, 128, 0.1) 1px, transparent 1px);
}

canvas{
}

.button-1-test{

  border: 1px solid rgb(90, 90, 90);
  /*
  background: rgb(247,247,247);
  background: -moz-linear-gradient(185deg, rgba(247,247,247,1) 0%, rgba(221,221,221,1) 100%);
  background: -webkit-linear-gradient(185deg, rgba(247,247,247,1) 0%, rgba(221,221,221,1) 100%);
  background: linear-gradient(185deg, rgba(247,247,247,1) 0%, rgba(221,221,221,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f7f7f7",endColorstr="#dddddd",GradientType=1);
  */

  background-color: white;
  border-radius: 4px;
  padding: 4px 10px;
  margin: 4px;

  color: rgb(255, 140, 0);
  font-weight: 500;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  letter-spacing: 0.03em;
}
.button-1-test:hover{
  cursor: pointer;
  filter: brightness(0.95);
}
.button-1-test:active{
  cursor: pointer;
  filter: brightness(1.05);
}

.button-1-test:disabled{
  cursor: initial;
  filter: grayscale(100%);
  opacity: 0.25;
}

.brush-button{
  margin: 3px;
  height: 29px;
  width: 29px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgb(71, 71, 71);
}
.brush-button:hover{
  cursor: pointer;
  opacity: 0.6;
}

.brush-button:active{
  cursor: pointer;
  opacity: 0.2;
}

.brush-button:disabled{
  cursor: initial;
  filter: grayscale(100%);
  opacity: 0.25;
}

.brush-button img{
}

.button-2-test{
  border: 0.2em solid rgb(3, 121, 200);
  background-color: rgb(251, 250, 249);
  border-radius: 4px;
  padding: 4px 10px;
  margin: 4px;

  color: rgb(3, 121, 200);
  font-weight: 500;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  letter-spacing: 0.03em;

  
}

.button-2-test:hover{
  cursor: pointer;
  filter: brightness(0.95);

}
.button-2-test:active{
  cursor: pointer;
  filter: brightness(1.05);

}

.button-2-test:disabled{
  cursor: initial;
  opacity: 0.5;

}
.button-2-test:disabled:hover{
  filter: brightness(1);

}

input[type="color"] {
  /* Basic appearance adjustments */
  border: 1px solid #333; /* Solid border with a dark color */
  border-radius: 4px; /* Rounded corners */
  background-color: #f0f0f0; /* White background */
  
  /* Size adjustments */
  width: 50px; /* Fixed width */
  height: 30px; /* Fixed height */
  padding: 2px; /* Inner spacing */
  
  /* Cursor change on hover */
  cursor: pointer;
  
  /* Remove outline on focus for a cleaner look */
  outline: none;
  margin-right: 5px;
}

input[type="color"]:hover {
  /* Slightly darker border on hover for visual feedback */
  border-color: #111;
}

input[type="color"]:focus {
  /* Change border color on focus for accessibility */
  border-color: #007bff;
}
.url-link{
  font-family:Arial, Helvetica, sans-serif;
  color: rgb(0, 119, 255);
}
.url-link:visited{
   color: rgb(0, 119, 255);
   
}

.layer-container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.layer-container button{
  margin: 3px;
  height: 30px;
  width: 100px;
  background-color: rgb(234, 235, 243);
  border-radius: 4px;
}

.group-container{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-top: 7px;
  margin-bottom: 4px;

}
.undo-redo-container{
  display: flex;
  justify-content: center;
  align-items: center;

}

.slider-custom {
  -webkit-appearance: none;
  width: 250px;
  height: 25px;
  /*
  background: linear-gradient(to right,#a0a0a0 0%, #a0a0a0 0%,#a0a0a0 10%, #767676 10%, #767676 20%, #a0a0a0 20%, #a0a0a0 30%, #767676 30%, #767676 40%, #a0a0a0 40%, #a0a0a0 50%, #767676 50%, #767676 60%, #a0a0a0 60%, #a0a0a0 70%, #767676 70%, #767676 80%, #a0a0a0 80%, #a0a0a0 90%, #767676 90%, #767676 100%, #a0a0a0 100%);
  */
  background: #a0a0a0;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider-custom:hover {
  opacity: 1;
}

.slider-custom::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 26px;
  height: 30px;
  background: #052c74;
  cursor: pointer;
  z-index: 1;
}

.slider-custom::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #045faa;
  cursor: pointer;
}

.slider-custom::before {
  width: var(--fill, 0%);
}



.tooltip {
  padding: 4px 8px;
  background-color: black;
  color: white;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 30px;
}
.icon-button{
  background-color: white;
  border: 2px solid rgb(44, 44, 44);
  border-radius: 6px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  margin: 3px;
}
.icon-button-inner-container{
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.icon-button-inner-container img{
  height: 40px;
  width: 40px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.icon-button:hover{
  cursor: pointer;
  background-color: rgb(236, 236, 236);
}
.icon-button:active{
  cursor: pointer;
  background-color: rgb(214, 214, 214);
}
.icon-button:disabled{
  cursor: initial;
  opacity: 0.5;
}

/**/
.render-button{
  background: #0b70dc;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  margin: 3px;
}

.render-button:hover{
  cursor: pointer;
  filter: brightness(1.1);
}
.render-button:active{
  cursor: pointer;
  filter: brightness(0.9);
}
.render-button:disabled{
  cursor: initial;
  opacity: 0.5;
}


.customFileUpload {
  display: flex;
  width: 200px;
  margin: auto;
  padding: 4px 8px;
  cursor: pointer;
  background-color: #007bff27;
  color: rgb(0, 0, 0);
  border: 2px solid #007bff;
  border-radius: 14px;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 5px;
}

.customFileUpload img {
  vertical-align: middle;
  margin-right: 8px;
}

.customFileUpload:hover{
  background-color: #007bff41;
}

.selected-music-name{
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin: 0px;
}


.btn-outline:hover{
  cursor: pointer;
  filter: brightness(1.05);
}

/*
.btn-outline{
  border: 3px solid rgb(175, 43, 199);
  background-color: rgb(232, 224, 242);
  color: rgb(175, 43, 199);
  font-weight: 600;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}



.btn-big{
  padding: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.btn-small{
  padding: 10px;
  height: 60px;
  width: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  overflow: hidden;
    white-space: nowrap;
}

.icon-large{
  height: 30px;
  width: 30px;
}
  */