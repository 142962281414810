.container-licensing{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
    padding: 100px;
}

.license-section{
    width: 60vw;
}