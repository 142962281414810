

.devblog-container {
    position: absolute;
    z-index: 5;
    width: 80vw;
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(1px);
    -webkit-backdrop-filter: blur(1px);
    padding: 10vw;
    padding-top: 50px;
    margin-top: -22px;
    align-items: flex-start;
    color: rgb(255, 255, 255);
}

.devblog-container *{
    text-align: left;
    
}

.devblog-container h2{
    font-size: 1.6em;

}
.devblog-container h3{
    font-size: 1.4em;
}
.devblog-container h4{
margin-top: -10px;
font-size: 0.9em;
}


hr{
    width: 70vw;
}

.devblog-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.944);
    
    z-index: 4;
    display: block;
    justify-content: center;
    align-items: center;
}

.devblog-overlay2{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;
    background-size: 20px 20px;
    background-image:
      linear-gradient(to right, rgb(255, 255, 255) 1px, transparent 1px),
      linear-gradient(to bottom, rgb(255, 255, 255) 1px, transparent 1px);

      display: block;
      justify-content: center;
      align-items: center;
      filter: blur(1px);
}
.devblog-overlay3{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 1);
    z-index: 2;
    display: block;
    justify-content: center;
    align-items: center;
}